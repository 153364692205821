import styled from 'styled-components';

import loadingImg from 'assets/loading.png';

const Loading = styled.img`
  align-self: center;
  margin: 10px;
  width: 200px;
  animation: loading-spin infinite 1s ease-in-out;

  @keyframes loading-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingSpinner = () => {
  return <Loading src={loadingImg} alt="loading" />;
};

import React from 'react';
import styled, { css } from 'styled-components';

export interface ButtonProps {
  children: string | JSX.Element | JSX.Element[];
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  primary?: boolean;
  secondary?: boolean;
}

export const Button = ({ children, disabled, onClick, primary, secondary }: ButtonProps) => {
  return (
    <StyledButton onClick={onClick} $primary={primary} $secondary={secondary} disabled={disabled}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<{ $primary?: boolean; $secondary?: boolean }>`
  align-self: center;
  background: transparent;
  border-radius: 3px;
  border: 2px solid ${(props) => (props.$primary ? '#225790' : '#4d6e91')};
  color: #225790;
  cursor: pointer;
  font-size: 1.2em;
  margin: 0.25em 0.5em;
  outline: 0;
  padding: 0.5rem 1rem;
  transition: ease background-color 250ms;

  &:disabled {
    background-color: #c9c9c9;
    border: 2px solid #a2a2a2;
    pointer-events: none;
  }

  &:hover {
    background-color: #548ecd;
  }

  ${(props) =>
    props.$primary &&
    css`
      background: #225790;
      color: white;
    `}
  ${(props) =>
    props.$secondary &&
    css`
      background: #4d6e91;
      color: white;
    `}
`;

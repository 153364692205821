import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import { clearSearchHistory, removeFromSearchHistory } from 'app/pokemonSlice';
import { Button } from './common/Button';
import { SearchHistory } from './SearchHistory';

interface SearchProps {
  history?: string[];
  onClear: () => void;
  onSubmit: (value?: string) => void;
  placeholder?: string;
  setText: (value: string) => void;
  text: string;
}

// TODO: Store/load history from local storage

export const Search = ({ history, onClear, onSubmit, placeholder, text, setText }: SearchProps) => {
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [showHistory, setShowHistory] = useState(false);

  const handleSubmit = () => {
    if (!text || !text?.length) {
      setError('Please enter text to search');
      return;
    }
    onSubmit();
  };

  const handleClearHistory = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // prevent the event from causing blur event
    event.preventDefault();
    // prevent the event from causing a search to fire
    event.stopPropagation();
    dispatch(clearSearchHistory());
  };

  const handleHistoryClick = (historyString: string) => {
    // we are having the string get searched immediately, but update state for consistency
    setText(historyString);
    onSubmit(historyString);
  };

  const handleHistoryRemove = (event: React.MouseEvent<SVGSVGElement>, historyString: string) => {
    // prevent the event from causing blur event
    event.preventDefault();
    // prevent the event from causing a search to fire
    event.stopPropagation();
    dispatch(removeFromSearchHistory(historyString));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // allow enter to submit the form
    if (event.key === 'Enter') {
      handleSubmit();
      event.currentTarget.blur();
    }
  };

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setError('');
    // we will get an onchange event fired when the user clicks to clear the input
    // in this case the value will be empty so we call onClear
    if (value === '') {
      onClear();
    }
    // we set value even if we are clearing since its an empty string
    setText(value);
  };

  const onFocus = (event: React.FormEvent<HTMLInputElement>) => {
    // select all current text when focused
    event.currentTarget.select();
    setShowHistory(true);
  };

  return (
    <SearchContainer>
      <SearchForm>
        <InputContainer>
          <Input
            onBlur={() => setShowHistory(false)}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
            placeholder={placeholder || 'Enter text to search...'}
            type="search"
            value={text}
          />
          {showHistory && !!history?.length && (
            <SearchHistory
              history={history}
              handleClearHistory={handleClearHistory}
              handleHistoryRemove={handleHistoryRemove}
              handleHistoryClick={handleHistoryClick}
            />
          )}
        </InputContainer>
        <Button onClick={handleSubmit} primary disabled={!text}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </Button>
      </SearchForm>
      <Error>{error}</Error>
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  display: flex;
  margin: 30px 10px;
  margin-bottom: 20px;
  align-self: center;
  flex-direction: column;
`;

const SearchForm = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 700px) {
    width: 500px;
  }
`;

const Input = styled.input`
  width: 100%;
  font-size: 1.2rem;
  color: #17171b;
  padding: 0.5rem 1rem;
  position: relative;

  &::-webkit-search-cancel-button {
    padding: 1px;
  }
  &::-webkit-search-cancel-button:hover {
    cursor: pointer;
  }
`;

const Error = styled.div`
  color: #e15a5a;
  font-weight: bold;
  height: 1em;
`;

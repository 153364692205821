import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuffle } from '@fortawesome/free-solid-svg-icons';

import { Button } from './Button';

type NavBarProps = {
  hasGoBack?: boolean;
};

function NavBar(props: NavBarProps) {
  const navigate = useNavigate();

  const navToRandom = () => {
    navigate(`/pokemon/${Math.ceil(Math.random() * 500)}`);
  };

  return (
    <Nav>
      <Inner>
        <StyledLink to="/">Pokedex</StyledLink>
        <Button secondary onClick={navToRandom}>
          <>
            <FontAwesomeIcon icon={faShuffle} />
            <ButtonText>Random</ButtonText>
          </>
        </Button>
      </Inner>
    </Nav>
  );
}

export default NavBar;

const Nav = styled.nav`
  background-color: #555555;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: calc(100% - 20px);
  z-index: 2;
`;

const Inner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  width: calc(100% - 20px);
`;

const StyledLink = styled(Link)`
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
`;

const ButtonText = styled.span`
  font-weight: bold;
  padding-left: 5px;

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

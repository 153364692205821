import { ReactElement } from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
  max?: number;
  progress: number;
  width?: number;
}

export function ProgressBar({ progress, max = 100, width = 300 }: ProgressBarProps): ReactElement {
  return (
    <Container aria-valuemax={max} aria-valuemin={0} aria-valuenow={progress} role="progressbar" width={width}>
      <Bar>
        <FilledBar data-testid="progress-bar-bar" width={(progress / max) * 100}></FilledBar>
      </Bar>
    </Container>
  );
}

const Container = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
`;

const Bar = styled.div`
  background: rgba(173, 188, 203, 0.7);
  border-radius: 0.5rem;
  height: 1rem;
  width: 100%;
`;

const FilledBar = styled(Bar)<{ width: number }>`
  background: #548ecd;
  transition: width 0.5s ease-out;
  width: ${(props) => props.width}%;
`;

import { styled } from 'styled-components';

import notFoundImage from 'assets/notfound.png';

export const PokemonNotFound = () => {
  return (
    <NotFoundContainer>
      <h1>Pokemon Not Found</h1>
      <Image src={notFoundImage} alt={'not found'} />
      <h4>Try searching a different Pokemon name.</h4>
    </NotFoundContainer>
  );
};

const NotFoundContainer = styled.div``;

const Image = styled.img``;

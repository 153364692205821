import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { styled } from 'styled-components';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Page>
      <Header>Oops!</Header>
      <p>Sorry, an unexpected error has occurred.</p>
      <Text>
        {isRouteErrorResponse(error)
          ? // note that error is type `ErrorResponse`
            error.error?.message || error.statusText
          : 'Unknown error message'}
      </Text>
    </Page>
  );
}

const Page = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100vh;
  justify-content: center;
`;

const Header = styled.h1`
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
`;
const Text = styled.p`
  color: rgb(148 163 184);
`;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BasePokemon } from 'types';

export interface PokemonCardProps {
  pokemon: BasePokemon;
}

export const PokemonCard = ({ pokemon }: PokemonCardProps) => {
  return (
    <StyledLink to={`/pokemon/${pokemon.name}`}>
      <Card data-testid={'pokemon-card'}>
        <TopInfo>
          <Id>#{String(pokemon.id).padStart(3, '0')}</Id>
          <Name>{pokemon.name}</Name>
        </TopInfo>
        <Image src={pokemon.sprites.other.home.front_default || ''} alt={pokemon.name} loading="lazy" />
      </Card>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const Card = styled.div`
  background-color: #fefefe;
  border-radius: 12px;
  border: 1px solid black;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 15px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transform: scale(1.05);
  }
`;

const TopInfo = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  line-height: 1.4em;
`;

const Id = styled.div`
  color: grey;
  font-size: 1em;
`;

const Name = styled.div`
  font-size: 1.8em;
  font-weight: bold;
  text-transform: capitalize;
`;

const Image = styled.img`
  max-width: 100%;
`;

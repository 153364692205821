import React from 'react';
import styled from 'styled-components';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface SearchHistoryProps {
  history: string[];
  handleClearHistory: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleHistoryClick: (historyString: string) => void;
  handleHistoryRemove: (event: React.MouseEvent<SVGSVGElement>, historyString: string) => void;
}

export const SearchHistory = ({
  history,
  handleClearHistory,
  handleHistoryClick,
  handleHistoryRemove,
}: SearchHistoryProps) => {
  return (
    <HistoryContainer>
      <HistoryTitle>
        Recent Searches
        <ClearButton onMouseDown={handleClearHistory}>Clear</ClearButton>
      </HistoryTitle>
      {history?.map((h) => (
        <HistoryItem key={h} onMouseDown={() => handleHistoryClick(h)}>
          <span>{h}</span>
          <FontAwesomeIcon icon={faCircleXmark} onMouseDown={(e) => handleHistoryRemove(e, h)} />
        </HistoryItem>
      ))}
    </HistoryContainer>
  );
};

const HistoryContainer = styled.div`
  background-color: #ffffff;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin-top: 0.25rem;
  max-height: 14rem;
  overflow-y: auto;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  position: absolute;
  text-align: left;
  top: 38px;
  width: 100%;
`;

const HistoryTitle = styled.div`
  color: #7f7f7f;
  display: flex;
  font-size: 1.5em;
  font-weight: bold;
  justify-content: space-between;
  padding: 10px;
`;

const ClearButton = styled.button`
  background-color: #7f7f7f;
  border-radius: 18px;
  color: #fff;
  border: none;
  padding: 0 0.5em;
`;

const HistoryItem = styled.div`
  color: #7f7f7f;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;

  &:hover {
    background-color: #f2f2f2;
  }

  & svg {
    color: #7f7f7f;
  }
`;

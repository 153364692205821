import styled from 'styled-components';
import { PokemonType } from 'types';
import {
  faBolt,
  faBrain,
  faBug,
  faCrosshairs,
  faDove,
  faDragon,
  faDumbbell,
  faFireFlameCurved,
  faGhost,
  faHillRockslide,
  faLeaf,
  faMoon,
  faMound,
  faQuestion,
  faSkullCrossbones,
  faSnowflake,
  faStarOfLife,
  faUniversalAccess,
  faWater,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TypeBadge = ({ type }: { type: PokemonType }) => {
  // font awesome icons: https://fontawesome.com/search
  const { name: typeName } = type.type;

  const getIcon = (name: string) => {
    switch (name) {
      case 'bug':
        return <FontAwesomeIcon icon={faBug} />;
      case 'dark':
        return <FontAwesomeIcon icon={faMoon} />;
      case 'dragon':
        return <FontAwesomeIcon icon={faDragon} />;
      case 'electric':
        return <FontAwesomeIcon icon={faBolt} />;
      case 'fairy':
        return <FontAwesomeIcon icon={faStarOfLife} />;
      case 'fighting':
        return <FontAwesomeIcon icon={faCrosshairs} />;
      case 'fire':
        return <FontAwesomeIcon icon={faFireFlameCurved} />;
      case 'flying':
        return <FontAwesomeIcon icon={faDove} />;
      case 'ghost':
        return <FontAwesomeIcon icon={faGhost} />;
      case 'grass':
        return <FontAwesomeIcon icon={faLeaf} />;
      case 'ground':
        return <FontAwesomeIcon icon={faMound} />;
      case 'ice':
        return <FontAwesomeIcon icon={faSnowflake} />;
      case 'normal':
        return <FontAwesomeIcon icon={faUniversalAccess} />;
      case 'poison':
        return <FontAwesomeIcon icon={faSkullCrossbones} />;
      case 'psychic':
        return <FontAwesomeIcon icon={faBrain} />;
      case 'rock':
        return <FontAwesomeIcon icon={faHillRockslide} />;
      case 'steel':
        return <FontAwesomeIcon icon={faDumbbell} />;
      case 'water':
        return <FontAwesomeIcon icon={faWater} />;
      default:
        return <FontAwesomeIcon icon={faQuestion} />;
    }
  };
  return (
    <Badge title={typeName} $typeName={typeName}>
      {getIcon(typeName)}
      {typeName}
    </Badge>
  );
};

/* pokemon type colors: https://gist.github.com/apaleslimghost/0d25ec801ca4fc43317bcff298af43c3   */
const getTypeBackgroundColor = (typeName: string) => {
  const typeColors: { [key: string]: string } = {
    bug: '#A6B91A',
    dark: '#705746',
    dragon: '#6F35FC',
    electric: '#F7D02C',
    fairy: '#D685AD',
    fighting: '#C22E28',
    fire: '#EE8130',
    flying: '#A98FF3',
    ghost: '#735797',
    grass: '#7AC74C',
    ground: '#E2BF65',
    ice: '#96D9D6',
    normal: '#A8A77A',
    poison: '#A33EA1',
    psychic: '#F95587',
    rock: '#B6A136',
    steel: '#B7B7CE',
    water: '#6390F0',
  };

  return typeColors[typeName];
};

const Badge = styled.div<{ $typeName: string }>`
  align-items: center;
  background-color: lightgray; // fallback
  background-color: ${({ $typeName }) => getTypeBackgroundColor($typeName)};

  border-radius: 10px;
  color: white;
  display: flex;
  font-size: 1.5em;
  margin: 5px;
  padding: 8px 16px;
  text-transform: capitalize;

  & svg {
    padding-right: 5px;
  }
`;

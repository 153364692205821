import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { BasePokemon } from 'types';
import { fetchMultiplePokemon, getPokemonByName, getPokemonSpecies } from 'api';
import { AppThunk, RootState } from './store';

export interface PokemonState {
  pokedex: BasePokemon[];
  selectedPokemon: BasePokemon | null;
  selectedEvolutionChain: BasePokemon[];
  status: 'idle' | 'loading' | 'failed';
  searchHistory: string[];
  searchStatus: 'idle' | 'searching' | 'failed';
}

export const initialState: PokemonState = {
  pokedex: [],
  selectedPokemon: null,
  selectedEvolutionChain: [],
  status: 'idle',
  searchHistory: [],
  searchStatus: 'idle',
};

export const getPokemon = createAsyncThunk(
  'pokemon/fetchPokemon',
  async ({ limit, offset }: { limit?: number; offset?: number } = {}) => {
    const response = await fetchMultiplePokemon(limit, offset);
    const allPokemon = await Promise.all(
      response.results.map(async (result: { url: string }) => {
        const res = await fetch(result.url);
        const data = await res.json();

        return data;
      })
    );
    return allPokemon;
  }
);

export const getMorePokemon = (): AppThunk => (dispatch, getState) => {
  const { pokedex } = selectPokemon(getState());
  dispatch(getPokemon({ limit: 20, offset: pokedex.length }));
};

export const getPokemonEvolution = createAsyncThunk('pokemon/getEvolution', async (pokemonName: string, ThunkAPI) => {
  ThunkAPI.dispatch(clearEvolutionChain());
  const speciesRes = await getPokemonSpecies(pokemonName);
  // get id from the evolution chain url. Will be in the form https://pokeapi.co/api/v2/evolution-chain/1/
  const res = await fetch(speciesRes?.evolution_chain?.url);
  const evolutionChain = await res.json();
  const { evolves_to } = evolutionChain.chain;
  // evolutions will contain the names of the pokemon in the evolution chain, fetch them
  const evolutions: string[] = [
    evolutionChain?.chain?.species?.name,
    evolves_to?.[0]?.species?.name,
    evolves_to?.[0]?.evolves_to?.[0]?.species?.name,
  ].filter((x) => x !== undefined); // we may have less than 3 evolutions so remove any missing

  const allEvolutions = await Promise.all(
    evolutions.map(async (name: string) => {
      const data = await getPokemonByName(name);

      return data;
    })
  );
  if (allEvolutions) {
    return allEvolutions;
  } else {
    return ThunkAPI.rejectWithValue('No Evolutions Found');
  }
});

export const searchPokemon = createAsyncThunk('pokemon/searchPokemon', async (searchString: string, ThunkAPI) => {
  ThunkAPI.dispatch(addToSearchHistory(searchString));
  // try to find the pokemon in our existing pokedex first so we don't have to hit API
  const { pokemon } = ThunkAPI.getState() as RootState;
  const found = pokemon.pokedex.find((p) => p.name === searchString);
  if (found) {
    return found;
  }
  // not found in pokedex. Search via API
  const response = await getPokemonByName(searchString);
  if (response) {
    return response;
  } else {
    return ThunkAPI.rejectWithValue('No Pokemon Found');
  }
});

const addToPokedex = (pokedex: BasePokemon[], newPokemon: BasePokemon[]) => {
  let newPokedex = [...pokedex, ...newPokemon];
  // remove duplicates from pokedex in case we fetch the same chunk (will happen in dev mode with strict enabled)
  let filtered = newPokedex.filter((pokemon, idx, arr) => arr.findIndex((p) => p.id === pokemon.id) === idx);
  // sort by pokemon number
  return filtered.sort((a, b) => a.id - b.id);
};

export const pokemonSlice = createSlice({
  name: 'pokemon',
  initialState,
  reducers: {
    addToSearchHistory: (state, action: PayloadAction<string>) => {
      // only maintain human readable strings as search history
      if (!isNaN(parseInt(action.payload))) {
        return;
      }
      const newHistory = [...state.searchHistory, action.payload];
      state.searchHistory = [...new Set(newHistory)];
    },
    clearEvolutionChain: (state) => {
      state.selectedEvolutionChain = [];
    },
    clearSearchHistory: (state) => {
      state.searchHistory = [];
    },
    removeFromSearchHistory: (state, action: PayloadAction<string>) => {
      state.searchHistory = state.searchHistory.filter((item) => item !== action.payload);
    },
    setSearchedPokemon: (state, action: PayloadAction<BasePokemon>) => {
      state.selectedPokemon = action.payload;
    },
    removeSelectedPokemon: (state) => {
      state.searchStatus = 'idle';
      state.selectedPokemon = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPokemon.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPokemon.fulfilled, (state, action) => {
        state.status = 'idle';
        // let newPokedex = [...state.pokedex, ...action.payload];
        // remove duplicates from pokedex in case we fetch the same chunk (will happen in dev mode with strict enabled)
        // state.pokedex = newPokedex.filter((pokemon, idx, arr) => arr.findIndex((p) => p.id === pokemon.id) === idx);
        state.pokedex = addToPokedex(state.pokedex, action.payload);
      })
      .addCase(getPokemon.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(searchPokemon.pending, (state) => {
        state.searchStatus = 'searching';
        state.selectedPokemon = null;
      })
      .addCase(searchPokemon.fulfilled, (state, action) => {
        state.searchStatus = 'idle';
        state.pokedex = addToPokedex(state.pokedex, [action.payload]);
        state.selectedPokemon = action.payload;
      })
      .addCase(searchPokemon.rejected, (state) => {
        state.searchStatus = 'failed';
        state.selectedPokemon = null;
      })
      .addCase(getPokemonEvolution.pending, (state) => {
        state.selectedEvolutionChain = [];
      })
      .addCase(getPokemonEvolution.fulfilled, (state, action) => {
        state.selectedEvolutionChain = action.payload;
      })
      .addCase(getPokemonEvolution.rejected, (state) => {
        state.selectedEvolutionChain = [];
      });
  },
});

export const {
  addToSearchHistory,
  clearEvolutionChain,
  clearSearchHistory,
  removeFromSearchHistory,
  removeSelectedPokemon,
  setSearchedPokemon,
} = pokemonSlice.actions;

export const selectPokemon = (state: RootState) => state.pokemon;

export default pokemonSlice.reducer;

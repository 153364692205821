import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  clearEvolutionChain,
  getPokemonEvolution,
  removeSelectedPokemon,
  searchPokemon,
  selectPokemon,
} from 'app/pokemonSlice';

import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { PokemonCard } from 'components/PokemonCard';
import { ProgressBar } from 'components/common/ProgressBar';
import { TypeBadge } from 'components/TypeBadge';

export const PokemonDetails = () => {
  const { pokemonName } = useParams();
  const dispatch = useAppDispatch();
  const { searchStatus, selectedPokemon, selectedEvolutionChain } = useAppSelector(selectPokemon);

  // scroll to top on mount or when param name changes. TODO: Move to common component
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pokemonName]);

  useEffect(() => {
    if (pokemonName) {
      dispatch(searchPokemon(pokemonName));
      dispatch(getPokemonEvolution(pokemonName));
    }
    return () => {
      // when we leave this page, remove this pokemon as the selected one
      dispatch(removeSelectedPokemon());
      dispatch(clearEvolutionChain());
    };
  }, [dispatch, pokemonName]);

  // to properly show the progress bar for a stat, we need to know the max possible for that stat
  // I'm not sure how accurate these are. This is the results of some googling
  const getStatMax = (statName: string) => {
    switch (statName) {
      case 'hp':
        return 255;
      case 'attack':
        return 190;
      case 'defense':
        return 230;
      case 'speed':
        return 200;
      case 'special-attack':
        return 180;
      case 'special-defense':
        return 230;
      default:
        return 100;
    }
  };

  return (
    <Container data-testid={'pokemon-details'}>
      {searchStatus === 'searching' && <LoadingSpinner />}
      {searchStatus === 'idle' && selectedPokemon && (
        <>
          <Row>
            <CardContainer>
              <PokemonCard pokemon={selectedPokemon} />
              <Row>
                <SpriteImage
                  data-testid={'pokemon-sprite'}
                  src={selectedPokemon.sprites.front_default || ''}
                  alt="front-sprite"
                />
                <SpriteImage
                  data-testid={'pokemon-sprite'}
                  src={selectedPokemon.sprites.back_default || ''}
                  alt="back-sprite"
                />
                <SpriteImage
                  data-testid={'pokemon-sprite'}
                  src={selectedPokemon.sprites.versions['generation-v']['black-white']['animated'].front_default || ''}
                  alt="animated-sprite"
                />
              </Row>
            </CardContainer>
            <StatsColumn>
              <PokemonStats>
                <Stat>
                  <StatName>Height</StatName>
                  <StatValue>
                    {selectedPokemon.height} <StatUnit>decimetres</StatUnit>
                  </StatValue>
                </Stat>
                <Stat>
                  <StatName>Weight</StatName>
                  <StatValue>
                    {selectedPokemon.weight} <StatUnit>hectograms</StatUnit>
                  </StatValue>
                </Stat>
                {selectedPokemon.stats.map(({ base_stat, stat }) => (
                  <Stat key={stat.name}>
                    <StatName>{stat.name.replace('-', ' ')}</StatName>
                    <StatValue>{base_stat}</StatValue>
                    <ProgressBar progress={base_stat} max={getStatMax(stat.name)} />
                  </Stat>
                ))}
                <Stat>
                  <StatName>Type</StatName>
                  <Row>
                    {selectedPokemon.types.map((t) => (
                      <TypeBadge key={t.slot} type={t} />
                    ))}
                  </Row>
                </Stat>
                <Stat>
                  <StatName>Ability</StatName>
                  <StatValue>{selectedPokemon.abilities[0].ability.name}</StatValue>
                </Stat>
              </PokemonStats>
            </StatsColumn>
          </Row>
          <EvolutionContainer>
            <h1>Evolution</h1>
            <Row>
              {selectedEvolutionChain.length > 0 &&
                selectedEvolutionChain.map((pokemon, idx) => {
                  return (
                    <React.Fragment key={pokemon.name}>
                      {idx !== 0 && (
                        <EvolveArrow>
                          <FontAwesomeIcon icon={faForward} />
                        </EvolveArrow>
                      )}
                      <CardContainer data-testid={'pokemon-evolution'}>
                        <PokemonCard pokemon={pokemon} />
                      </CardContainer>
                    </React.Fragment>
                  );
                })}
            </Row>
          </EvolutionContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 24px;
  max-width: 1280px;
  padding: 2em 10em;
  width: calc(100% - 20em);

  @media only screen and (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media only screen and (max-width: 1280px) {
    flex-wrap: wrap;
  }
`;

const CardContainer = styled.div`
  max-width: 500px;
  padding-top: 0.8em;
`;

const SpriteImage = styled.img`
  background-color: #fefefe;
  border-radius: 12px;
  border: 1px solid black;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  margin: 10px;
  padding: 15px;
  text-decoration: none;
  flex-basis: 0;
  flex-shrink: 0;
  flex-grow: 1;
`;

const PokemonStats = styled.div`
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 5px;
  grid-template-columns: repeat(2, 1fr);
  padding: 0.5em 2em;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const EvolutionContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
`;

const StatsColumn = styled.div``;

const Stat = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  padding: 0.8em;
`;

const StatName = styled.div`
  color: grey;
  font-size: 1.2em;
  text-transform: capitalize;
`;

const StatValue = styled.div`
  align-items: baseline;
  color: #548ecd;
  display: flex;
  font-size: 2.4em;
  font-weight: bold;
`;

const StatUnit = styled.div`
  color: #5e5e5e;
  font-size: 20px;
  font-weight: 400;
  padding-left: 2px;
`;

const EvolveArrow = styled.div`
  align-items: center;
  color: #548ecd;
  display: flex;
  font-size: 4em;
  justify-content: center;
  padding: 0.5em;
`;

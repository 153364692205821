import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { setupStore } from './app/store';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import App from 'App';
import { PokemonDetails } from 'pages/PokemonDetails';
import ErrorPage from 'pages/ErrorPage';
import { Pokedex } from 'pages/Pokedex';

const container = document.getElementById('root')!;
const root = createRoot(container);

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <Pokedex />,
      },
      {
        path: 'pokemon/:pokemonName',
        element: <PokemonDetails />,
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={setupStore()}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

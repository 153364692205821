/* PokeAPI Rules:
 *
 * Locally cache resources whenever you request them.
 * Be nice and friendly to your fellow PokéAPI developers.
 */

const baseUrl = 'https://pokeapi.co/api/v2/';

// Get multiple pokemon
// https://pokeapi.co/docs/v2#pokemon
export const fetchMultiplePokemon = async (limit = 20, offset = 0) => {
  const offsetString = offset ? `&offset=${offset}` : '';
  const res = await fetch(`${baseUrl}pokemon?limit=${limit}${offsetString}`);
  const data = await res.json();
  return data;
};

// Get one pokemon by exact name match
// https://pokeapi.co/docs/v2#pokemon
export const getPokemonByName = async (name: string) => {
  if (!name || !name?.length) return;
  const res = await fetch(`${baseUrl}pokemon/${name}`);
  if (res.status !== 200) {
    // the pokemon was not found... handle gracefuly
    return null;
  }
  const data = await res.json();
  return data;
};

// Get one pokemon species
// https://pokeapi.co/docs/v2#pokemon-species
export const getPokemonSpecies = async (name: string) => {
  if (!name || !name?.length) return;
  const res = await fetch(`${baseUrl}pokemon-species/${name}`);
  if (res.status !== 200) {
    // the pokemon was not found... handle gracefuly
    return null;
  }
  const data = await res.json();
  return data;
};

// Get one pokemon species
// https://pokeapi.co/docs/v2#pokemon-species
export const getEvolutionChain = async (id: number) => {
  if (!id) return;
  const res = await fetch(`${baseUrl}evolution-chain/${id}`);
  if (res.status !== 200) {
    // the pokemon was not found... handle gracefuly
    return null;
  }
  const data = await res.json();
  return data;
};

import React from 'react';
import { Outlet } from 'react-router-dom';

import './App.css';
import Nav from 'components/common/Nav';

function App() {
  return (
    <>
      <Nav />
      <div className="App">
        <div className="App-bg" />
        <Outlet />
      </div>
    </>
  );
}

export default App;

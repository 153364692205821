import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getPokemon, getMorePokemon, searchPokemon, selectPokemon, removeSelectedPokemon } from 'app/pokemonSlice';

import { Button } from 'components/common/Button';
import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { PokemonCard } from 'components/PokemonCard';
import { PokemonNotFound } from 'components/common/PokemonNotFound';
import { Search } from 'components/Search';

export const Pokedex = () => {
  const dispatch = useAppDispatch();
  const { pokedex, searchHistory, searchStatus, selectedPokemon, status } = useAppSelector(selectPokemon);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(getPokemon());
  }, [dispatch]);

  const submitSearch = (searchString?: string) => {
    // we allow search to directly pass a string to search instead of relying on state to update
    // this is useful if the user is selecting from the search history
    dispatch(searchPokemon(searchString || searchText));
  };

  const displayPokemonCards = () => {
    if (searchStatus === 'searching') {
      return <LoadingSpinner />;
    } else if (searchStatus === 'failed' && searchText) {
      return <PokemonNotFound />;
    } else if (selectedPokemon) {
      return (
        <PokemonList>
          <PokemonCard pokemon={selectedPokemon} />
        </PokemonList>
      );
    }
    return (
      <PokemonList>
        {pokedex.map((p) => (
          <PokemonCard key={p.id} pokemon={p} />
        ))}
      </PokemonList>
    );
  };

  return (
    <PokedexContainer>
      <Search
        history={searchHistory}
        onClear={() => dispatch(removeSelectedPokemon())}
        onSubmit={submitSearch}
        placeholder={'Enter name of pokemon to search...'}
        setText={setSearchText}
        text={searchText}
      />
      <p>click on a pokemon to get more detailed info</p>
      <ContentContainer>
        {displayPokemonCards()}
        {status !== 'idle' && <LoadingSpinner />}
        {!searchText && status === 'idle' && !selectedPokemon && (
          <Button onClick={() => dispatch(getMorePokemon())} primary>
            Load More
          </Button>
        )}
      </ContentContainer>
    </PokedexContainer>
  );
};

const PokedexContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  max-width: 1280px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 100px;
`;

const PokemonList = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  margin-bottom: 30px;

  @media only screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;
